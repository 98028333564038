import React from "react";
import Layout from "../components/layout";
import Nav from "../components/nav";
import './contact.css';

const lang = {
  en: { thanksText: 'Thank you, we will contact you soon!' },
  sv: { thanksText: 'Tack, vi kontaktar er inom kort!' },
};

const Thanks = () => (
  <Layout>
    <Nav />
    <div className="contact__header"></div>
    <div className="contact__thanks">
      <h1>{lang.en.thanksText}</h1>
    </div>
  </Layout>
)

export default Thanks;